import React from 'react';

const Landing = () => {
  return (
    <div className="landing" id="home">
      <h1>huy Chau</h1>
      <p>I'm a designer diving into the world of web and mobile development. I usually solve problems visually and now I'm doing it in code as well. Feel free to look around and if you have any questions, you can reach me at
        <strong> huychaudesign@gmail.com </strong> 
        Thanks for stopping by!</p>
      <div>
        <img
          src={require("../images/home-bg.jpg")}
          alt='landing-bg'
        />
      </div>
    </div>
  )
}

export default Landing;