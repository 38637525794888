import React from "react";
import engramLogo from '../images/svgs/engram-logo.svg';
import seshLogo from '../images/svgs/sesh-logo.svg';

const Projects = React.forwardRef((props, ref) => {
  const projectsInfo = [
    {
      name: "Engram",
      id: "dev",
      stacks: [
        "ReactJS",
        "React Query",
        "TailwindCSS",
        "Firebase",
      ],
      live: "https://en-gram.netlify.app/",
      code: "https://github.com/Huynie/enneagram/tree/vue",
      desc: "A personality test offering registered users features to track their mental health over time based on the enneagram health state system.  Built using firebase for backend database and user accounts, ReactJS and React Query for front end data handling and components.",
      image: (
        <img
          src={require("../images/engram-bg.jpg")}
          alt="EnGram Thumbnail"
          className="project__image--bg"

        />
      ),
      logo: (
        <>
          <img
            src={engramLogo}
            alt="Sesh Thumbnail"
            className="project__image--logo rotating"
          />
          <img
            src={engramLogo}
            alt="Sesh Thumbnail"
            className="project__image--logo rotating"
            style={{opacity: 0.3, marginTop: '2rem', marginLeft: '0.5rem', filter: 'drop-shadow(0px 0px 2px #F843AF) opacity(100%) blur(3px)'}}
          />
        </>   
      )
    },
    {
      name: "Keybz",
      id: "keyboard",
      stacks: [
        "NodeJS",
        "MongoDB",
        "Express",
        "ReactJS",
        "SaSS"
      ],
      live: "https://keybz.netlify.app/",
      code: "https://github.com/Huynie/keyboard-store",
      desc: " A keyboard store built using Nodejs, express, mongoDB, and ReactJS.  This is mock e-commerce store front focusing on front end interaction including shopping cart, checkout, and product listing features.",
      image: (
        <img
          src={require("../images/keybz.jpg")}
          alt="keybz Thumbnail"
          className="project__image--bg"
        />
      ),
    },
    {
      name: "Sesh",
      id: "sesh",
      stacks: [
        "NodeJS",
        "Express",
        "PostgresSQL",
        "ReactJS",
        "Bootstrap",
        "AWS"
      ],
      live: "https://seshn.netlify.app/",
      code: "https://github.com/Huynie/sesh",
      desc: " A web app listing practice spots for breakdancers. features include relvant information per spots, in-app liability waiver signing, spot search, and more to come.  Built using Google maps api, ReactJS, PostgresSQL, NodeJS, AWS Elastic beanstalk, S3 Bucket, API gateway",
      image: (
        <img
          src={require("../images/sesh-bg.jpg")}
          alt="Sesh Thumbnail"
          className="project__image--bg"
        />
      ),
      logo: (   
        <img
          src={seshLogo}
          alt="Sesh Thumbnail"
          className="project__image--logo"
        />
      )
    },
  ];
  return (
    <div
    className="project__container"
    id="project__wrapper"
    ref={ref}
    >
    {projectsInfo.map((project, idx) => {
        return (
        <div
          className="project__section"
          id={`scrollTrigger${idx}`}
          key={idx}
        >
            {/* ADJUST THE WIDTH ON THIS DIV TO CHANGE THE TITLE FLEX WIDTH */}
            <div className="project__title--container">
              <h1 className="project__title" id={`title${idx}`}>
                  {project.name}
                <div className="project__links">
                  <a href={project.code}>code</a>
                  <a href={project.live}>demo</a>
                </div>
              </h1>
            </div>
            <div className="project__content">
              <a href={project.live} className="project__image" id={`image${idx}`}>
                {project.image}
                {
                  project.logo ? 
                  project.logo :
                  null
                }
              </a>
            <div className="project__textBlock" id={`text${idx}`}>
                <p>{project.desc}</p>
                <div className="project__techStacks">
                {project.stacks.map((tech, ind) => (
                    <div key={ind}>{tech}</div>
                ))}
                </div>
                {/* <div className="project__links">
                  <a href={project.code}>code</a>
                  <a href={project.live}>demo</a>
                </div> */}
            </div>
            </div>
        </div>
        );
    })}
    </div>
  );
});

export default Projects;
