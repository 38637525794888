import * as React from 'react'

const Indicators = () => {
  const navSection = [
    'home',
    'engram',
    'keybz',
    'seshn',
    'resume'
  ]
  const navRef = React.useRef();

  const mobileClick = React.useCallback((e) => {
    const prevNav = e.target.parentNode.querySelector('.show');
    const currentNav = e.target.childNodes[1];
    if(prevNav) {
      prevNav.classList.remove('show');
    }
    
    if(currentNav){
      currentNav.click();
      currentNav.classList.add('show');
      return
    }
  },[]);

  React.useEffect(() => {
    const sect = document.querySelectorAll('.indicators__sections');
    sect.forEach(section => {
      section.addEventListener('click', mobileClick);
    })
    return () => {
      sect.forEach(section => {
        section.removeEventListener('click', mobileClick);
      })
    }
  },[mobileClick])

  return (
    <div className="indicators" ref={navRef}>
      {navSection.map((section, idx) => {
        const navSection = section !== 'home'&& section !== 'resume' ? `#scrollTrigger${idx-1}` : `#${section}`;
        return (
          <div className="indicators__sections" id={`navSection${idx}`} key={idx}>
            <div className="indicators__lines"></div>
            <a href={navSection}>{section}</a>
          </div>
        )
      })}
    </div>
  )
}

export default Indicators