import * as React from "react";

const Resume = React.forwardRef((props, ref) => {
  return (
    <div className="resume" id="resume" ref={ref}>
      <div className="resume__title">
        <h1>END.</h1>
      </div>
      <div className="resume__download">
        <h1>END.</h1>
        {/* <a href="/huyChauResume.pdf" download style={{display: 'flex'}}>
          resume
          <div style={{backgroundColor: 'transparent', marginLeft: '1rem'}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="resume__download--icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
          </div>
        </a> */}
        {/* <div></div> */}
      </div>
      <div className="resume__cred">
        <div className="resume__list">
          <h2 style={{ marginBottom: 0 }}>Huy Chau</h2>
          <h2 style={{ marginTop: 0 }}>Developer/ Designer</h2>
          <ul>
            <li>huychaudesign@gmail.com</li>
            <li>
              <a href="https://github.com/Huynie">github</a>
            </li>
            <li>
              <a href="https://linkedin.com/in/huynie/">linkedIn</a>
            </li>
          </ul>
        </div>
        <div className="resume__list">
          <h2>technologies</h2>
          <ul>
            <li>React JS</li>
            <li>React native</li>
            <li>Next JS</li>
            <li>typescript</li>
            <li>sass</li>
            <li>tailwind</li>
            <li>postgres SQL</li>
          </ul>
        </div>
        <div className="resume__list">
          <h2>work</h2>
          <ul>
            <li>lithion Battery Inc</li>
            <li>Casanova Dev Studio</li>
            <li>Virtual Dining Concepts</li>
            <li>Lesson connections</li>
            <li>Convync</li>
            <li>Ashley Hunt</li>
          </ul>
        </div>
        <div className="resume__list">
          <h2>projects</h2>
          <ul>
            <li>enGram</li>
            <li>Sesh</li>
            <li>Keybz</li>
          </ul>
        </div>
        {/* <div className="resume__list" id="downloadMobile">
          <h2>Resume</h2>
          <a href="/huyChauResume.pdf" download>click here</a>
        </div> */}
      </div>
    </div>
  );
});

export default Resume;
