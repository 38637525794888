import * as React from "react";
import Indicators from "./Indicators";

const Layout = ({ children }) => {
  return (
    <main id="container">
      <Indicators/>
      {children}
    </main>
  )
}

export default Layout;